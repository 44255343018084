import { RouteObject } from 'react-router-dom';

// Root
import DatabaseManagementPage from './DatabaseManagementPage';

// Equipment Databases
import EquipmentPage from './EquipmentDBs/Equipment/EquipmentPage';
import EquipmentForm from './EquipmentDBs/Equipment/EquipmentForm';
import HECertificateTypePage from './EquipmentDBs/HECertificateType/HECertificateTypePage';

// Servicing Databases
import PMSCodePage from './ServicingDBs/PMSCode/PMSCodePage';
import PMSCodeTaskPartsPage from './ServicingDBs/PMSCodeTaskParts/PMSCodeTaskPartsPage';
import PartsPage from './ServicingDBs/Parts/PartsPage';
import LaborTypePage from './ServicingDBs/LaborType/LaborTypePage';
import ServiceRequestTypePage from './ServicingDBs/ServiceRequestType/ServiceRequestTypePage';
import ServiceTaskPage from './ServicingDBs/ServiceTask/ServiceTaskPage';

// Project Databases
import ProjectPage from './ProjectDBs/Project/ProjectPage';
import ProjectActivityPage from './ProjectDBs/ProjectActivity/ProjectActivityPage';
import EquipmentPlannedShiftHoursPage from './ProjectDBs/EquipmentPlannedShiftHours/EquipmentPlannedShiftHoursPage';

// Personnel Databases
import HEOperatorPage from './PersonnelDBs/HEOperator/HEOperatorPage';
import HEOperatorCertificateTypePage from './PersonnelDBs/HEOperatorCertificateType/HEOperatorCertificateTypePage';
import MechanicPage from './PersonnelDBs/Mechanic/MechanicPage';

// Other Databases
import FuelTypePage from './OtherDBs/FuelType/FuelTypePage';
import LocationPage from './OtherDBs/Location/LocationPage';
import MeterTypePage from './OtherDBs/MeterType/MeterTypePage';
import RentalRatesPage from './OtherDBs/RentalRates/RentalRatesPage';
import FuelUpdatePage from './OtherDBs/FuelUpdate/FuelUpdatePage';
import FuelCostCodePage from './OtherDBs/FuelCostCode/FuelCostCodePage';

const DatabaseManagementRoutes: RouteObject = {
  path: '',
  element: <DatabaseManagementPage />,
  children: [
    // Equipment Databases
    {
      path: 'equipment',
      element: <EquipmentPage />,
    },
    {
      path: 'he-certificate-type',
      element: <HECertificateTypePage />,
    },
    // Servicing Databases
    {
      path: 'pms-code',
      element: <PMSCodePage />,
    },
    {
      path: 'pms-code-task-parts',
      element: <PMSCodeTaskPartsPage />,
    },
    {
      path: 'parts',
      element: <PartsPage />,
    },
    {
      path: 'service-request-type',
      element: <ServiceRequestTypePage />,
    },
    {
      path: 'service-task',
      element: <ServiceTaskPage />,
    },
    {
      path: 'labor-type-rate',
      element: <LaborTypePage />,
    },
    // Project Databases
    {
      path: 'project',
      element: <ProjectPage />,
    },
    {
      path: 'project-activity-cost-code',
      element: <ProjectActivityPage />,
    },
    {
      path: 'equipment-planned-shift-hours',
      element: <EquipmentPlannedShiftHoursPage />,
    },

    // Personnel Databases
    {
      path: 'mechanic',
      element: <MechanicPage />,
    },
    {
      path: 'he-operator',
      element: <HEOperatorPage />,
    },
    {
      path: 'he-operator-certificate-type',
      element: <HEOperatorCertificateTypePage />,
    },
    // Other Databases
    {
      path: 'meter-type-interval-type',
      element: <MeterTypePage />,
    },
    {
      path: 'fuel-type',
      element: <FuelTypePage />,
    },
    {
      path: 'location',
      element: <LocationPage />,
    },
    {
      path: 'rental-rates',
      element: <RentalRatesPage />,
    },
    {
      path: 'fuel-update',
      element: <FuelUpdatePage />,
    },
    {
      path: 'fuel-cost-code',
      element: <FuelCostCodePage />,
    },
    {
      path: 'equipment-new',
      element: <EquipmentForm />,
    },
    {
      path: 'equipment-new/:id',
      element: <EquipmentForm />,
    },
  ],
};

export default DatabaseManagementRoutes;
