import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { ICLCColumns, ICPCColumns, EFCR, EET } from './types';

const CLCMap = {
  'ESR Completion Date' : 'dateCompleted',
  'ESR No.' : 'esrNumber',
  'Equipment No.' : 'equipmentNo',
  'Category' : 'category',
  'Product Family' : 'productFamily',
  'Description' : 'description',
  'Make' : 'make',
  'Model' : 'model',
  'Capacity': 'capacity',
  'Project' : 'project',
  'Warranty' : 'warranty',
  'Technician Name' : 'technicianName',
  'Technician Type' : 'technicianType',
  'Man Hours' : 'manHours',
  'Unit Cost' : 'unitCost',
  'Total Labor Cost' : 'laborCost'
}

const CPCMap = {
  'ESR Completion Date' : 'dateCompleted',
  'ESR No.' : 'esrNumber',
  'Equipment No.' : 'equipmentNo',
  'Category' : 'category',
  'Product Family' : 'productFamily',
  'Description' : 'description',
  'Make' : 'make',
  'Model' : 'model',
  'Capacity': 'capacity',
  'Project' : 'project',
  'Warranty' : 'warranty',
  'Part No.' : 'partNumber',
  'System' : 'system',
  'Sub System' : 'subSystem',
  'Quantity' : 'quantity',
  'Unit Cost' : 'unitCost',
  'Total Parts Cost' : 'totalPartsCost'
}

const efcrMap = {
  'FRIS Date': 'frisDate',
  'FRIS No.': 'frisNo',
  'Equipment No.': 'equipmentNo',
  'Category': 'category',
  'Product Family': 'productFamily',
  'Description': 'description',
  'Make': 'make',
  'Model': 'model',
  'Capacity': 'capacity',
  'Project': 'project',
  'FRIS Completion Date': 'completionDate',
  'FRIS Time': 'time',
  'Quantity': 'quantity',
  'Meter Reading': 'meterReading',
  'Fuel Truck No.': 'fuelTruckNo',
  'Operator Name': 'operatorName',
  'Unit Cost': 'unitCost',
  'Total Parts Cost': 'totalPartsCost',
  'Ownership Type': 'ownershipType'
}

const EETMap = {
  'EJO Number' : 'ejoNumber',
  'EJO Creation Date' : 'ejoCreatedDate',
  'EJO Approval Date' : 'ejoApprovalDate',
  'ESR Number' : 'esrNumber',
  'ESR Creation Date': 'esrCreatedDate',
  'ESR Approval Date' : 'esrApprovalDate',
  'ESR Start Date' : 'esrStartDate',
  'ESR Completion Date' : 'esrCompletionDate',
  'ESR Service Request': 'serviceRequest',
  'ESR Remarks' : 'remarks',
  'Equipment No' : 'equipmentNumber',
  'Model' : 'model',
  'Product Family' : 'productFamily',
  'Make': 'make',
  'Project' : 'project',
  'Ownership Type': 'ownedOrRented'  
}

export const exportCLCToExcel = (data: ICLCColumns[], filename: string) => {
  const headings = [Object.keys(CLCMap)];
  const columnHeaders = [...Object.values(CLCMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportCPCToExcel = (data: ICPCColumns[], filename: string) => {
  const headings = [Object.keys(CPCMap)];
  const columnHeaders = [...Object.values(CPCMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEFCRToExcel = (data: EFCR[], filename: string) => {
  const headings = [Object.keys(efcrMap)];
  const columnHeaders = [...Object.values(efcrMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};

export const exportEETToExcel = (data: EET[], filename: string) => {
  const headings = [Object.keys(EETMap)];
  const columnHeaders = [...Object.values(EETMap)];

  const workbook: XLSX.WorkBook = XLSX.utils.book_new();
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);

  XLSX.utils.sheet_add_aoa(worksheet, headings);
  XLSX.utils.sheet_add_json(worksheet, data, {
    origin: 'A2',
    skipHeader: true,
    header: columnHeaders,
  });

  const currentDate = format(Date.now(), 'MM_dd_yyyy');
  const exportedFilename = `${filename}_${currentDate}`;

  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
    wch: 20,
  }));

  worksheet['!cols'] = wscols;

  XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
};