import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Equipment } from '../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { IEquipments } from '../modules/DatabaseManagement/EquipmentDBs/Equipment/utils/equipment-utils';
import equipmentApi from '../services/equipments';

interface ApiError {
  status: number;
  message: string;
}
interface useGetEquipmentOptions {
  dropdown?: boolean;
  productFamilyId?: string;
}

export function useGetEquipmentsQuery(options?: useGetEquipmentOptions) {
  return useQuery<Equipment[]>(['equipments'], () => equipmentApi.getEquipments(), {
    onError(err) {
      console.log(err);
    },
    select(equipments) {
      if (options?.dropdown) {
        const uniqueEquipments = equipments.filter(
          (obj, idx) => equipments.findIndex((eq) => eq.productFamily.categoryName === obj.productFamily.categoryName) === idx
        )

        return uniqueEquipments;
      } else {
        return equipments;
      }
    }
  });
}

export function useGetEquipmentsRatingQuery(options?: useGetEquipmentOptions) {
  return useQuery<Equipment[]>(['equipments'], () => equipmentApi.getEquipments(), {
    onError(err) {
      console.log(err);
    },
    select(equipments) {
      if (options?.productFamilyId && options?.productFamilyId.trim().length !== 0) {
        const equipment = equipments.filter(eq => eq.id === options?.productFamilyId);
        const productFamilyId = equipment[0].productFamilyId;
        const filteredEqByProductFamily = equipments.filter(e => e.productFamilyId === productFamilyId);

        const uniqueEquipmentsByCapacity = filteredEqByProductFamily.filter(
          (obj, idx) => filteredEqByProductFamily.findIndex((eq) => eq.capacity === obj.capacity) === idx
        )
        
        return uniqueEquipmentsByCapacity;
      } else {
        return equipments;
      }

      return equipments;
    }
  });
}

export function useGetEquipmentById() {
  const { id } = useParams<{ id: string }>();
  return useQuery([`equipments-${id}`, id], () => equipmentApi.getEquipmentsById(id!));
}

export const useExportEquipment = (data: IEquipments[]) => {
  const newEquipmentList = [];
  
  const EquipmentList = data;

  for(let i in EquipmentList){
    let json = {
      equipmentNo: EquipmentList[i].equipmentNo,
      category: EquipmentList[i].category,
      description: EquipmentList[i].description,
      make: EquipmentList[i].make,
      model: EquipmentList[i].model,
      capacity: EquipmentList[i].capacity,
      class: EquipmentList[i].class,
      serialNumber: EquipmentList[i].serialNumber,
      engineNumber: EquipmentList[i].engineNumber,
      chassisNumber: EquipmentList[i].chassisNumber,
      supplier: EquipmentList[i].supplier,
      conductionSticker: EquipmentList[i].conductionSticker,
      plateNumber: EquipmentList[i].plateNumber,
      yearModel: EquipmentList[i].yearModel,
      deliveryDate: EquipmentList[i].deliveryDate,
      ownershipType: EquipmentList[i].ownershipType,
      acquisitionCost: EquipmentList[i].acquisitionCost,
      isActive: EquipmentList[i].isActive,
      productFamily: EquipmentList[i].productFamily.categoryName,
    }
    newEquipmentList.push(json);
  }
  return newEquipmentList;
}