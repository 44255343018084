import { Box, Paper, Stack, TablePagination } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  AppTable,
  AppTableBody,
  AppTableCell,
  AppTableContainer,
  AppTableHead,
  AppTableRow,
  AppTableSort,
} from '../../../../components/common/app-table/ui';
import useDefaultTable from '../../../../components/common/app-table/useDefaultTable';
import FormAddButton from '../../../../components/common/FormAddButton';
import AppSearchField from '../../../../components/common/forms/AppSearchField';
import { ExportButton } from '../../../../components/common/primebmd-ui/DataButtons';
import { IEquipments, ExportEquipment } from './utils/equipment-utils';
import equipmentColumns from './equipmentColumn';
import { exportEquipmentToExcel } from './utils/exporting';
import { useGetEquipmentsQuery, useExportEquipment } from '../../../../hooks/useEquipments';
import LoadingIndicator from '../../../../components/common/LoadingIndicator';
import { useSnackBar } from '../../../../components/ToastProvider';

const EquipmentTable = () => {
  const { showSnackBar } = useSnackBar();
  const equipmentData = useGetEquipmentsQuery();
  const data: IEquipments[] = equipmentData?.data === undefined ? [] : equipmentData.data;
  const formattedData: ExportEquipment[] = useExportEquipment(data);


  const { table, dataCount, searchFilter, setSearchFilter, pageIndex, pageSize } = useDefaultTable<IEquipments>(
    data,
    equipmentColumns
  );
  
  const navigate = useNavigate();

  const emptyText = searchFilter ? 'No data found' : 'No data yet';

  const goToAddUserAccount = () => navigate('/equipment-new');

  const handleExport = () => {
    exportEquipmentToExcel(formattedData, 'Equipment Database');
  }

  if (equipmentData?.isLoading) return <LoadingIndicator show />;

  return (
    <>
      <Stack direction='row'  justifyContent='flex-end' mb={2} gap={2}>
        <ExportButton onClick={handleExport} />
        <AppSearchField
          onSearchKeywordChange={setSearchFilter}
          searchKeyword={searchFilter ?? ''}
        />
        <FormAddButton onClick={goToAddUserAccount} />
       
      </Stack>
      <Paper elevation={3}>
        <AppTableContainer>
          <AppTable stickyHeader sx={{ minWidth: '2300px' }}>
            <AppTableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <AppTableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <AppTableCell key={header.id} align='center'>
                      <AppTableSort
                        canSort={header.column.getCanSort()}
                        currentSort={header.column.getIsSorted()}
                        onSort={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </AppTableSort>
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableHead>
            <AppTableBody dataCount={dataCount} loading={equipmentData?.isLoading} emptyText={emptyText}>
              {table.getRowModel().rows.map((row) => (
                <AppTableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <AppTableCell key={cell.id} align='center'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </AppTableCell>
                  ))}
                </AppTableRow>
              ))}
            </AppTableBody>
          </AppTable>
        </AppTableContainer>
        <Box position='relative'>
          <TablePagination
            sx={{ bgcolor: 'white', borderTop: '1px solid #eee' }}
            component='div'
            count={dataCount}
            page={pageIndex}
            onPageChange={(e, page) => table.setPageIndex(page)}
            rowsPerPage={pageSize}
            onRowsPerPageChange={(e) => table.setPageSize(Number(e.target.value))}
            rowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      </Paper>
      {equipmentData?.isError && showSnackBar('Network Error', 'error')}
    </>
  );
};

export default EquipmentTable;
