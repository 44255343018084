import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import HeaderCellCheckbox from '../../../components/common/primebmd-ui/HeaderCellCheckbox';
import { dateDifference } from '../../../utils/helpers';
import { createColumnHelper } from '@tanstack/react-table';
import { appColors } from '../../../theme';

export interface AvailableItem {
  id: string;
  equipmentNo: string;
  productFamily: string;
  make?: string;
  alert: string;
  alertDate: Date | string;
  latestEndingReading: number;
}

const columnHelper = createColumnHelper<AvailableItem>();

export const availableItemColumns = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <HeaderCellCheckbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        indeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('equipmentNo', {
    id: 'equipmentNo',
    header: 'Equipment No.',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('productFamily', {
    id: 'productFamily',
    header: 'Product Family',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),

  columnHelper.accessor('make', {
    id: 'make',
    header: 'Make',
    cell: (row) => row.getValue(),
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.display({
    id: 'readyToWorkStatus',
    header: 'Ready to Work Status',
    cell: (row) => row.getValue() ? row.getValue() : '-',
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('alert', {
    id: 'alert',
    header: 'Alerts',
    cell: ({ row }) => {
      let pmAlertLatestReading = row.original.alert ? row.original.latestEndingReading :  0 ;
      let pmAlertCodeSplit = row.original.alert ? row.original.alert.split('_') : [] ;
      let pmAlertDiff = row.original.alert ? Number(pmAlertCodeSplit[1]) - pmAlertLatestReading : 0 ;
      return row.original.alert 
              ? <Chip 
                  label={row.original.alert ? row.original.alert  : '-'} 
                  sx={{ background: `${
                      pmAlertDiff <= 25
                        ? appColors.chartRed
                        : pmAlertDiff >= 26 && pmAlertDiff <= 50
                        ? appColors.chartYellow
                        : 'green'
                    }`, color: "#fff" }}
                /> 
              : '-'
    },
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
  columnHelper.accessor('alertDate', {
    id: 'daysSinceAlertStarted',
    header: '# of Days since Alert Started',
    cell: (row) => row.getValue() !== null ? dateDifference(new Date(), row.getValue()) : '-',
    enableGlobalFilter: true,
    enableColumnFilter: false,
  }),
];
