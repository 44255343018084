import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { Equipment } from '../../../../../modules/MyDashboardsPlusTasks/EUR/utils/types';
import { IEquipments, ExportEquipment } from './equipment-utils'

const EquipmentMap = {
    // 'id': 'id',
    'Equipment No.': 'equipmentNo',
    'Category': 'category',
    'Product Family': 'productFamily',
    'Description': 'description',
    'Make': 'make',
    'Model': 'model',
    'Capacity': 'capacity',
    'Class': 'class',
    'Serial No.': 'serialNumber',
    'Engine No.': 'engineNumber',
    'Chassis No.': 'chassisNumber',
    'Supplier': 'supplier',
    'Conduction Sticker': 'conductionSticker',
    'Plate No.': 'plateNumber',
    'Year Model': 'yearModel',
    'Date of Delivery': 'deliveryDate',
    'Ownership Type': 'ownershipType',
    'Acquisition Cost': 'acquisitionCost',
    'Status': 'isActive'
}

export const exportEquipmentToExcel = (data: ExportEquipment[], filename: string) => {
    const headings = [Object.keys(EquipmentMap)];
    const columnHeaders = [...Object.values(EquipmentMap)];
  
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([]);
  
    XLSX.utils.sheet_add_aoa(worksheet, headings);
    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: 'A2',
      skipHeader: true,
      header: columnHeaders,
    });
  
    const currentDate = format(Date.now(), 'MM_dd_yyyy');
    const exportedFilename = `${filename}_${currentDate}`;
  
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
  
    const wscols: XLSX.WorkSheet['!cols'] = headings[0].map((h, i) => ({
      wch: 20,
    }));
  
    worksheet['!cols'] = wscols;
  
    XLSX.writeFile(workbook, `${exportedFilename}.xlsx`);
  };