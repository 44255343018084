import * as yup from 'yup';

export interface IAccounts {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  isActive: boolean;
  roleId: string; // Role string[]
  roleName: string;
  dateCreated: string;
  dateUpdated: string;
}
export interface AccountsExport {
  firstName: string;
  lastName: string;
  isActive: string | boolean;
  roleName: string;
  dateCreated: string;
  dateUpdated: string;
}

export const accountsSchema = yup.object({
  firstName: yup.string().required('First Name is required').trim(),
  lastName: yup.string().required('Last Name is required').trim(),
  contactNumber: yup
    .string()
    .required('Contact Number is required')
    .min(11, 'Contact Number must have more than 10 numbers')
    .trim(),
  email: yup
    .string()
    .required('Email is required')
    .trim()
    .email('Must be a valid email'),
  roleName: yup.string().trim(),
  roleId: yup.string().required('Role ID is required').trim(),
  isActive: yup.boolean().optional().nullable(),
});

export type accountsFields = Omit<
  IAccounts,
  'id' | 'dateCreated' | 'dateUpdated'
>;

export const userAccountsInitialValues: accountsFields = {
  firstName: '',
  lastName: '',
  contactNumber: '',
  email: '',
  isActive: true,
  roleName: '',
  roleId: '',
};

export const roleOptions = [
  {
    id: 'superAdmin',
    label: 'SuperAdmin',
    value: 'SuperAdmin',
  },
  {
    id: 'manager',
    label: 'Manager',
    value: 'Manager',
  },
  {
    id: 'admin',
    label: 'Admin',
    value: 'admin',
  },
];
