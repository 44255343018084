import { useQuery } from '@tanstack/react-query';
import { IEquipmentDeploymentStatus, IEquipmentStatusData, IEquipmentReadyToWorkData, OperationalCountData } from '../modules/Dashboard/db-utils';
import homeApi from '../services/home';

interface equipmentStatusProps {
  status?: string;
}

export function useGetEquipmentStatusQuery(props?: equipmentStatusProps ) {
  return useQuery<IEquipmentDeploymentStatus[]>(['equipmentStatus'], () => homeApi.getEquipmentStatus(), {
    onError(err) {
      console.log(err);
    },
    select(equipmentStatus){
      if (props?.status) {
        return equipmentStatus.filter(e => e.status === props.status);
      }
      return equipmentStatus;
    }
  });
}

export function useGetOnRentEquipmentQuery() {
  return useQuery<IEquipmentStatusData[]>(['equipmentStatusData'], () => homeApi.getOnRentEquipmentStatus(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useGetAvailableEquipmentQuery() {
  return useQuery<IEquipmentStatusData[]>(['equipmentStatusData'], () => homeApi.getAvailableEquipmentStatus(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useGetOffRentEquipmentQuery() {
  return useQuery<IEquipmentStatusData[]>(['equipmentStatusData'], () => homeApi.getOffRentEquipmentStatus(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useGetInTransitEquipmentQuery() {
  return useQuery<IEquipmentStatusData[]>(['equipmentStatusData'], () => homeApi.getInTransitEquipmentStatus(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useGetOperationalQuery() {
  return useQuery<IEquipmentReadyToWorkData[]>(['equipmentReadyToWorkOperationalData'], () => homeApi.getOperationalEquipment(), {
    onError(err) {
      console.log(err);
    }
  });
}

export function useGetUnderServicingQuery() {
  return useQuery<OperationalCountData>(['equipmentReadyToWorkUnderServicingData'], () => homeApi.getUnderServicingEquipment(), {
    onError(err) {
      console.log(err);
    }
  });
}
