import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import { formatDate } from '../../../../utils/helpers';
import { IEquipments } from './utils/equipment-utils';
import EquipmentsActionButton from './utils/EquipmentsActionButtons';

const columnHelper = createColumnHelper<IEquipments>();

const equipmentColumns = [
    columnHelper.accessor('equipmentNo', {
        id: 'equipmentNo',
        header: 'Item No.',
        cell: ({row}) => row.index +1,
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('equipmentNo', {
        id: 'equipmentNo',
        header: 'Equipment No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('category', {
        id: 'category',
        header: 'Category',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('productFamily.categoryName', {
        id: 'productFamily.categoryName',
        header: 'Product Family',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('make', {
        id: 'make',
        header: 'Make',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('model', {
        id: 'model',
        header: 'Model',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('capacity', {
        id: 'capacity',
        header: 'Capacity',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('class', {
        id: 'class',
        header: 'Class',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('serialNumber', {
        id: 'serialNumber',
        header: 'Serial No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('engineNumber', {
        id: 'engineNumber',
        header: 'Engine No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('chassisNumber', {
        id: 'chassisNumber',
        header: 'Chassis No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('supplier', {
        id: 'supplier',
        header: 'Supplier',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('conductionSticker', {
        id: 'conductionSticker',
        header: 'Conduction Sticker',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('plateNumber', {
        id: 'plateNumber',
        header: 'Plate No.',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('yearModel', {
        id: 'yearModel',
        header: 'Year Model',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('deliveryDate', {
        id: 'deliveryDate',
        header: 'Date of Delivery',
        cell: (row) => moment(row.getValue()).format('MM/DD/yyyy'),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('ownershipType', {
        id: 'ownershipType',
        header: 'Ownership Type',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('acquisitionCost', {
        id: 'acquisitionCost',
        header: 'Acquisition Cost',
        cell: (row) => row.renderValue(),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.accessor('isActive', {
        id: 'isActive',
        header: 'Status',
        cell: (row) => (row.getValue() ? 'Active' : 'Inactive'),
        enableGlobalFilter: true,
        enableColumnFilter: true,
    }),
    columnHelper.display({
        id: 'actions',
        header: 'Action',
        cell: ({ row }) => <EquipmentsActionButton equipment={row.original} />,
        enableHiding: false,
    })
];

export default equipmentColumns;
