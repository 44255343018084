import { Box, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../components/AuthProvider';
import PrimeLogo from '../../assets/images/logo-full.jpeg';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AuthActionButton from '../../components/common/AuthActionButton';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import authApi from '../../services/auth';
import { useSnackBar } from '../../components/ToastProvider';

type AuthFields = { email: string; password: string };

const loginSchema = yup.object({
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const LoginPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { showSnackBar } = useSnackBar();

  const auth = useAuth();
  const [isPassword, setIsPassword] = useState(true);
  const loginMutation = useMutation(({ email, password }: AuthFields) =>
    authApi.login(email, password)
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthFields>({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const onSubmit = ({ email, password }: AuthFields) => {
    loginMutation.mutate(
      { email, password },
      {
        onSuccess(data) {
          auth?.setAuthUser(data);
          showSnackBar('Successfully Logged In', 'success');
        },
        onError(error: any) {
          // if(error?.response?.status === 401){
          // return showSnackBar('Email / Password is incorrect', 'error');
          // }
          showSnackBar('User Not Found', 'error');
        },
      }
    );
  };
  // const { status } = useAppSelector((state) => state.auth);

  // if (status === 'loading') return <LoadingIndicator show />;

  if (auth?.authUser) {
    return <Navigate to='/home/on-rent' replace />;
  }

  return (
    <Box
      sx={{
        padding: 6,
        boxShadow: '0px 0px 15px rgba(0,0,0,0.15)',
        margin: 10,
        borderRadius: 2,
        bgcolor: 'white',
      }}
    >
      <Stack mb={4}>
        <img
          src={PrimeLogo}
          alt='primebmd'
          width='auto'
          height={75}
          style={{ alignSelf: 'center' }}
        />
      </Stack>
      <Stack component='form' onSubmit={handleSubmit(onSubmit)} sx={{ width: '300px' }}>
        <Stack spacing={1} width='300px' mb={1}>
          <Typography fontSize={13} component='label' htmlFor='email'>
            Email
          </Typography>
          <TextField
            size='small'
            placeholder='Email'
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            inputProps={{ sx: { fontSize: 14 } }}
          />
        </Stack>
        <Stack spacing={1} width='300px' mb={2}>
          <Typography fontSize={13} component='label' htmlFor='password'>
            Password
          </Typography>
          <TextField
            size='small'
            type={isPassword ? 'password' : 'text'}
            placeholder='Password'
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
            InputProps={{
              sx: { fontSize: 14 },
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() => setIsPassword((prev) => !prev)}
                    aria-label='toggle password visibility'
                    edge='end'
                  >
                    {isPassword ? (
                      <VisibilityOff fontSize='small' />
                    ) : (
                      <Visibility fontSize='small' />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* {serverError && (
          <FormHelperText
            error
            sx={{ pt: 2, justifyContent: 'center', display: 'flex' }}
          >
            {serverError}
          </FormHelperText>
        )} */}
        </Stack>
        <Stack gap={2} alignItems='center'>
          <AuthActionButton
            label='Login'
            isForLogin={false}
            isLoading={loginMutation.isLoading}
            width={180}
          />
          <Typography fontSize={14}>
            Forgot Password? Click <Link to={`/auth/forgot-password`}>Here</Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LoginPage;
